@import "./globals.scss";

.portfolio-footer {
    background-color: #1b1e2e;
    padding: 20px;
    display: flex;
    justify-content: center;
    p {
        color: rgb(255, 255, 255);
        margin: 0;
    }
}
