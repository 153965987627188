@import "./globals.scss";

.portfolio-career {
    background-color: $background-color_2;
    padding: 0 50px;
    .career {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .career-education, .career-professionnal{
            display: flex;
            flex-direction: column;
        }
        h2 {
            color: white;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: -15px;
                width: 2px;
                height: 100%;
                background-color: $primary-color;
                margin: 0 auto;
            }
        }
        .career-item {
            display: flex;
            flex-direction: column;
            align-self: center;
            width: 80%;
            margin: 20px 0;
            padding: 20px;
            border-radius: 10px;
            background-color: $background-color_1;
            box-shadow: 0px 0px 10px 0px $primary-color;
            h3 {
                color: $primary-color;
                font-size: 1.2rem;
                margin: 0;
            }
            p {
                color: white;
                font-size: 1.2rem;
                margin: 0;
                padding: 10px 0;
            }
            ul {
                li {
                    list-style-type: circle;
                    color: white;
                    font-size: 1rem;
                    margin: 0;
                    padding: 5px 10px;
                    border-radius: 10px;
                    margin: 5px;
                }
            }
        }
        @media (max-width: $tablet) {
            flex-direction: column;
            align-items: center;
        }
    }
    @media (max-width: $mobile) {
        padding-top: 50px;
    }
}
