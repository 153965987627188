$font-family_1: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-family_2: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

body {
	margin: 0;
	font-family: $font-family_1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
code {
	font-family: $font-family_2;
}
