@import "./globals.scss";

.portfolio-projects {
    padding-top: 50px;
    background: $background-color_2;
    .projects {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 50px;
        .project {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 10px;
            border: 1px solid $primary-color;
            margin: 20px;
            padding: 10px 30px;
            width: auto;
            .project-head {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 310px;
                border-radius: 10px;
                background: #2c3e50;
                margin: 25px 0;
                img {
                    padding: 10px 0 10px 10px;
                    width: 40px;
                    height: 40px;
                    @media (max-width: $tablet) {
                        width: 30px;
                        height: 30px;
                    }
                }
                h3 {
                    color: white;
                    font-size: 1.7rem;
                    margin: 0 50px 0;
                    @media (max-width: $tablet) {
                        font-size: 1.2rem;
                    }
                }
                @media (max-width: $tablet) {
                    width: 250px;
                }
            }
            .project-body {
                width: 310px;
                height: 100%;
                border-radius: 10px;
                margin-bottom: 26px;
                img {
                    width: 100%;
                    height: auto;
                    border-radius: 10px;
                }
                p {
                    color: white;
                    font-size: 1.2rem;
                    margin: 10px 0;
                    @media (max-width: $tablet) {
                        font-size: 1rem;
                    }
                }
                a {
                    width: 150px;
                    margin: 0 auto;
                    text-align: center;
                }
                @media (max-width: $tablet) {
                    width: 250px;
                }
            }
        }
    }
    @media (max-width: $mobile) {
        padding-top: 50px;
    }
}
