@import "./globals.scss";

.portfolio-skills {
    padding-top: 50px;
    height: auto;
    background: $background-color_2;
    h1:after {
        width: 140px;
    }
    .skills {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .frontEnd,
        .backEnd,
        .devOps {
            display: flex;
            justify-content: space-between;
            margin: 0 50px;
            .frontEnd-logo,
            .backEnd-logo,
            .devOps-logo {
                width: 70%;
                display: flex;
                flex-wrap: wrap;
                .skill {
                    width: 150px;
                    height: 150px;
                    display: flex;
                    flex-direction: column;
                    img {
                        width: 100px;
                        height: 100px;
                        margin: 10px auto;
                        @media (max-width: $tablet) {
                            width: 70px;
                            height: 70px;
                        }
                    }
                    p {
                        color: white;
                        font-size: 20px;
                        margin: auto;
                    }
                }
                @media (max-width: $tablet) {
                    width: 100%;
                    justify-content: center;
                }
            }
            .frontEnd-presentation,
            .backEnd-presentation,
            .devOps-presentation {
                display: flex;
                flex-direction: column;
                > div {
                    padding: 20px;
                    border-radius: 50%;
                    background-color: #34495e;
                }
                width: 30%;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 50px;
                    height: 50px;
                }
                p {
                    color: white;
                    font-size: 20px;
                    font-weight: bold;
                    text-align: center;
                }
                @media (max-width: $tablet) {
                    display: none;
                }
            }
            @media (max-width: $tablet) {
                flex-direction: column;
                margin: 0 20px;
            }
        }
        @media (max-width: $tablet) {
            height: 100%;
            justify-content: start;
        }
    }
    @media (max-width: $mobile) {
        padding-top: 50px;
    }
}
