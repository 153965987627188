@import "./globals.scss";

.burger {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    background-color: $background-color_1;
    border-radius: 20px;
    &:before,
    &:after {
        content: "";
        display: block;
        width: 25px;
        height: 3px;
        background-color: $primary-color;
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
    }
    &:hover {
        cursor: pointer;
    }
    @media (min-width: $mobile) {
        display: none;
    }
}

.cross {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    background-color: $background-color_1;
    border-radius: 20px;
    &:before,
    &:after {
        content: "";
        display: block;
        width: 25px;
        height: 3px;
        background-color: $primary-color;
    }
    &:before {
        transform: rotate(45deg);
        margin-top: 2px;
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        cursor: pointer;
    }
    @media (min-width: $mobile) {
        display: none;
    }
}

.portfolio-navigation,
.portfolio-navigation-mobile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 175px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $background-color_1;
    z-index: 20;
}

.portfolio-navigation {
    @media (max-width: $mobile) {
        display: none;
    }
}

.portfolio-navigation-mobile {
    @media (min-width: $mobile) {
        display: none;
    }
}
