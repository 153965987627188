@import "./globals.scss";

.portfolio-about {
    height: 100vh;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background: $background-color_2;
    @media (max-width: $mobile) {
        flex-direction: column;
        padding-top: 50px;
    }
    .profile-picture {
        width: 50%;
        display: flex;
        justify-content: center;
        @media (max-width: $mobile) {
            width: 100%;
        }
        img {
            width: 250px;
            height: auto;
            border-radius: 20%;
            @media (max-width: $mobile) {
                width: 190px;
            }
        }
    }
    .profile-presentation {
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        @media (max-width: $mobile) {
            width: 100%;
            align-items: center;
            height: unset;
            margin-top: 20px;
        }
        p {
            padding: 0 100px 0 20px;
            margin-left: -20px;
            font-size: 1.3rem;
            line-height: 1.5;
            color: white;
            border-left: 1px solid $primary-color;
            .name,
            .school,
            .company {
                position: relative;
                color: $primary-color;
                font-weight: bold;
                transition: all 0.3s ease;
                cursor: default;
                &:after {
                    position: absolute;
                    left: 0;
                    bottom: -3px;
                    content: "";
                    display: block;
                    width: 0;
                    height: 2px;
                    background: $primary-color;
                    transition: width 0.3s;
                }
                &:hover:after {
                    width: 100%;
                }
            }
            @media (max-width: $mobile) {
                width: 80%;
                padding: 0 10px;
                margin-left: 10px;
                font-size: 15px;
            }
        }
        .cv {
            margin-top: 20px;
            text-align: center;
        }
    }
    @media (max-width: $tablet) {
        height: auto;
    }
}
